import React from "react";

import Dashboard from "../components/Dashboard/Dashboard";

const HomePage = () => {
  return (
      <Dashboard />
  );
};

export default HomePage;
